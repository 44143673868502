.spinner {
  width: 100px;
  height: 100px;
  pointer-events: none;

  @media (prefers-reduced-motion: no-preference) {
    animation: logo-spin infinite 1.5s;
  }

  @keyframes logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
