@import './scss/index.scss';

#root {
  margin: 0;
  overflow: auto;
  width: 100vw;
  height: 100vh;
}

p {
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

::-webkit-resizer {
  background-color: transparent;
  background-image: url('assets/svg/textArea-Corner.svg');
}

::-webkit-scrollbar {
  width: 14px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 99999px;
  background-color: #00000020;
}
